import { useEffect, useState } from "react";
import styled from "styled-components";

const Timer = styled.div`
  background-color: #e96500;
  width: 100;
  color: white;
  font-family: "Poppins";
  text-align: center;
  padding: 10px;

  span {
    margin: 0px 3px;
  }
`;

export default function Counter() {
  const [time, setTime] = useState(60);

  const FormatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingMinutes = seconds % 60;
    return `${minutes}:${remainingMinutes < 10 ? "0" : ""}${remainingMinutes}`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Timer>
      ESSA OPORTUNIDADE VAI ACABAR EM:
      <span>{FormatTime(time)}</span>
    </Timer>
  );
}

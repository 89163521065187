import React, { useEffect, useState } from "react";
import elementsData from "./Elements.json";
import styled, { css, keyframes } from "styled-components";

const pulse = keyframes`
  0% { transform: scaleX(1); }
  50% { transform: scale3d(1.5, 1.5, 1.5); }
  100% { transform: scaleX(1); }
`;

const BgDiv = styled.div`
  background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/550d7024-ab5e-40d8-eb45-19b7c898f400/public);
  background-size: cover;
  padding: 15px;
  display: flex;
  justify-content: center;
  margin-top: 150px;
`;

const AnimatedImage = styled.img<{ animate: boolean }>`
  width: 100%;
  height: auto;
  ${({ animate }) =>
    animate &&
    css`
      animation: ${pulse} 2s infinite;
    `}
`;

interface Element {
  id: string;
  image: string;
}

interface ScratchCardProps {
  onScratch: (scratched: boolean) => void;
  animate: boolean; // Prop to control animation
}

const Background: React.FC<ScratchCardProps> = ({ onScratch, animate }) => {
  const [matrix, setMatrix] = useState<Element[][]>([]);
  const [scratchComplete] = useState(false);

  useEffect(() => {
    const elements = elementsData as Element[];

    const element1 = elements
      .filter((el) => el.id === "1")
      .slice(0, 1)
      .concat(elements.filter((el) => el.id === "1").slice(0, 1))
      .concat(elements.filter((el) => el.id === "1").slice(0, 1));

    const otherElements = elements.filter((el) => el.id !== "1");
    const extendedElements = [
      ...element1,
      ...otherElements.flatMap((el) => [el, el]), // Repeat each element twice
    ];

    const shuffledElements = extendedElements.sort(() => Math.random() - 0.5);
    const matrix: Element[][] = [];

    for (let i = 0; i < 3; i++) {
      matrix.push(shuffledElements.slice(i * 3, i * 3 + 3));
    }

    setMatrix(matrix);
  }, []);

  useEffect(() => {
    if (scratchComplete) {
      console.log("Scratch complete, triggering animation");
      onScratch(true);
    }
  }, [scratchComplete, onScratch]);

  return (
    <BgDiv>
      <div
        style={{
          display: "grid",
          width: "300px",
          margin: "auto",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "10px",
          userSelect: 'none'
        }}
      >
        {matrix.map((row, rowIndex) =>
          row.map((item, colIndex) => (
            <AnimatedImage
              key={`${rowIndex}-${colIndex}`}
              src={item.image}
              alt={`Element ${item.id}`}
              animate={animate && item.id === "1"}
            />
          ))
        )}
      </div>
    </BgDiv>
  );
};

export default Background;

import styled from "styled-components";

// Create a styled img component
const HeadImg = styled.img`
  aspect-ratio: 293 / 154;
  display: block;
  margin: auto;
  padding: 10px 0px;

  @media screen and (min-width: 768px) {
    aspect-ratio: 293 / 154;
  }
`;

export default function Headline() {
  return (
    <>
      <HeadImg
        src={
          "head.webp"
        }
      />
    </>
  );
}

import React, { useState } from "react";
import ScratchCard from "./ScratchCard/scratchCardGame";
import Background from "./ScratchCard/scratchCardBG";
import Modal from "./Modal/Modal";
import GlobalStyle from "./Fonts/GlobalStyle";
import styled, { keyframes } from "styled-components";
import Headline from "./Headline/Headline";
import Counter from "./counter/counter";
import { useLocation } from "react-router-dom";

const Main = styled.div`
  position: relative;
`;

const Scratch = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const CardDiv = styled.div`
  position: absolute;
  top: 0;
  display: block;
  margin: auto;
  width: 100%;
  background-image: url(https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/ac3417a6-da5d-4fe3-8270-16472a376700/public);
  background-size: cover;

  @media screen and (min-width: 768px) {
    width: 414px;
    display: block;
    margin: auto;
  }
`;

const ScratchCardDiv = styled.div`
  margin-top: 140px;
`;

const BackgroundDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonDiv = styled.div`
  background-image: url(https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/81c708b4-2aa3-403c-4b86-39c4daae2b00/public);
  background-size: 100%;
  aspect-ratio: 425 / 135;
  display: block;
  margin: 15px auto 0px auto;
  z-index: 10000;
  width: 98%;
  transform: translateY(-5%);
  height: 120px;

  button {
    background: none;
    border: none;
    display: block;
    margin: auto;

    img {
      width: 280px;
      display: block;
      margin: auto;
    }
  }

  @media screen and (min-width: 768px) {
    width: 400px;
  }
`;

const scratchAnimation = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(-55deg); }
  100% { transform: rotate(0deg); }
`;

const Btn = styled.div`
  background-image: url(https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/5397cc09-1bc9-4a2c-79b4-be63061e2200/public);
  width: 310px;
  height: 50px;
  background-size: 100% 100%;
  margin: 5px 0px;
  position: relative;
  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      transform: scaleX(1);
    }
  }
`;

// Use the defined keyframes in your styled component
const Hand = styled.img`
  width: 20px !important;
  position: absolute;
  right: 45px;
  top: 15px;
  animation: ${scratchAnimation} 2s infinite;
`;

const App: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [, setScratchedPercent] = useState(0);
  const [animate, setAnimate] = useState(false); // State to control animation
  const [cover] = useState(true);
  const [btn, setBtn] = useState(true);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("_token");

  const handleScratch = (scratched: boolean) => {
    if (scratched) {
      setTimeout(() => {
        setShowModal(true);
        setBtn(false);
      }, 2000);
      setAnimate(true);
    }
  };

  const handleScratchedPercentChange = (percent: number) => {
    setScratchedPercent(percent);
    if (percent > 70) {
      handleScratch(true);
    }
  };

  // const handleClick = () => {
  //   setCover(false);
  //   setAnimate(true);
  //   setTimeout(() => {
  //     setShowModal(true);
  //   }, 5000);
  // };

  return (
    <Main>
      <Counter />
      <GlobalStyle />
      <Headline />
      <Scratch>
        <BackgroundDiv>
          <Background onScratch={handleScratch} animate={animate} />
        </BackgroundDiv>
        <CardDiv>
          <ScratchCardDiv>
            {cover && (
              <ScratchCard
                width={350}
                height={350}
                image={
                  "https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/b3c90200-77c5-4f6e-f541-5802c16ed400/public"
                }
                brushSize={30}
                onScratch={handleScratch}
                onScratchedPercentChange={handleScratchedPercentChange}
              />
            )}
          </ScratchCardDiv>
        </CardDiv>
      </Scratch>
      <ButtonDiv>
        {btn && (
          <button>
            <Btn>
              <Hand
                src={
                  "https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/11b50325-d462-4ca0-0180-9f627a55ec00/public"
                }
              />
            </Btn>
          </button>
        )}
      </ButtonDiv>
      {showModal && <Modal token={token} />}
    </Main>
  );
};
export default App;

import React, { useRef, useEffect, useState, useCallback } from "react";
import styled from "styled-components";

const Canvas = styled.canvas`
  display: block;
  margin: auto;
`

interface ScratchCardProps {
  width: number;
  height: number;
  image: string;
  brushSize: number;
  onScratch: (scratched: boolean) => void;
  onScratchedPercentChange: (percent: number) => void;
}

const ScratchCard: React.FC<ScratchCardProps> = ({ width, height, image, brushSize, onScratch, onScratchedPercentChange }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(new Image(width, height));
  const [isScratching, setIsScratching] = useState(false);

  useEffect(() => {
    const img = imageRef.current;
    img.src = image;
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d");
      if (canvas && context) {
        context.drawImage(img, 0, 0, width, height);
        context.globalCompositeOperation = "destination-out";
      }
    };

    return () => { // Cleanup on unmount
      img.onload = null;
    }
  }, [image, width, height]);

  const calculateScratchedPercent = useCallback(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (canvas && context) {
      const pixels = context.getImageData(0, 0, width, height).data;
      const totalPixels = width * height;
      let scratchedPixels = 0;

      for (let i = 0; i < pixels.length; i += 4) {
        if (pixels[i + 3] === 0) {
          scratchedPixels++;
        }
      }

      const scratchedPercent = (scratchedPixels / totalPixels) * 100;
      if (scratchedPercent > 80) {
        onScratch(true);
      }
      onScratchedPercentChange(scratchedPercent);
    }
  }, [width, height, onScratch, onScratchedPercentChange]);

  const startScratching = () => setIsScratching(true);
  const stopScratching = () => {
    setIsScratching(false);
    calculateScratchedPercent();
  };

  const scratch = useCallback((x: number, y: number) => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (canvas && context) {
      context.beginPath();
      context.arc(x, y, brushSize, 0, Math.PI * 2, true);
      context.fill();
    }
  }, [brushSize]);

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isScratching) return;
    const rect = canvasRef.current?.getBoundingClientRect();
    if (!rect) return; // Ensure rect is defined before proceeding
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    scratch(x, y);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLCanvasElement>) => {
    if (!isScratching) return;
    const rect = canvasRef.current?.getBoundingClientRect();
    if (!rect) return; // Ensure rect is defined before proceeding
    const touch = e.touches[0];
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    scratch(x, y);
  };


  return (
    <>
      <Canvas
        ref={canvasRef}
        width={width}
        height={height}
        onMouseDown={startScratching}
        onMouseMove={handleMouseMove}
        onMouseUp={stopScratching}
        onTouchStart={startScratching}
        onTouchMove={handleTouchMove}
        onTouchEnd={stopScratching}
      />
    </>

  );
};

export default ScratchCard;

import styled from "styled-components";
import icon from "../assets/hangloose.svg";

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  width: 340px;
  height: 3rem;
  border: 1px solid #8bcdff;
  border-radius: 8px;
`;
const IconStyled = styled.img`
  margin: 0 5px;
`;

const TextStyled = styled.p`
  font-family: "Gotham";
  color: #ffffff !important;
  font-weight: normal;
`;

const SpanStyled = styled.span`
  font-family: "Gotham-Medium" !important;
  color: #e1af5f !important;
`;

interface ModalOptionsProps {
  message: string | null;
  deposit: string | null;
}

const ModalOptions: React.FC<ModalOptionsProps> = ({ message, deposit }) => {
  return (
    <OptionContainer>
      <IconStyled src={icon} alt="nois-bet" />
      <TextStyled>
        {message} | <SpanStyled>{deposit}</SpanStyled>
      </TextStyled>
    </OptionContainer>
  );
};

export default ModalOptions;

import React from "react";
import styled from "styled-components";
import ModalOptions from "../ModalOptions/ModalOptions";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); // Dark background with 50% opacity
  z-index: 1000;
`;

const ModalContent = styled.div`
  min-height: 433px;
  padding: 40px 20px;
  border-radius: 5px;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 105px;
  gap: 25px;
  text-align: center;
  position: relative;
  background-image: url(https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/8e96e2d4-a0d1-41bd-cafc-185dadcc1f00/public);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: 0.3s;

  h1 {
    font-family: "Gotham-Medium";
    color: white;
    font-size: 24px;
    max-width: 80%;
    font-weight: normal;

    span {
      font-family: "Gotham-Black";
      font-weight: bold;
    }
  }

  a {
    text-decoration: none;
    color: white;
    font-family: "Gotham-Thin";
    div {
      padding: 10px 0px;
      border: 1px solid #cdc5c5;
      margin: 15px 0px;
      border-radius: 8px;
    }
  }
  div {
    button {
      background: none;
      border: none;
    }
  }
  span {
    font-family: "Gotham-Black";
  }
  @media screen and (min-width: 768px) {
    max-width: 450px;
  }
  h2 {
    color: white;
    font-family: "Gotham-Black";
    font-size: 22px;
  }
  p {
    color: #7d7d84;
    font-family: "Gotham-Medium";
  }
`;

// const CloseButton = styled.span`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   font-size: 24px;
//   cursor: pointer;
//   color: #7d7d84;
// `;

const ImageStyled = styled.img`
  width: 150px;
  height: auto;
`;

const CTA = styled.img`
  width: 250px;
  animation: pulse 1s infinite;
  @keyframes pulse {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      transform: scaleX(1);
    }
  }
`;

interface ModalProps {
  token: string | null;
}

const Modal: React.FC<ModalProps> = ({ token }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <h1>
          Você ganhou <span> RODADAS GRÁTIS</span> no seu primeiro depósito!
        </h1>
        <ModalOptions message={"20 Rodadas Grátis"} deposit={"Deposite R$10"} />
        <ModalOptions message={"40 Rodadas Grátis"} deposit={"Deposite R$20"} />
        <ModalOptions
          message={"100 Rodadas Grátis"}
          deposit={"Deposite R$50"}
        />
        <a href={`https://nbraspadinha.com/?_lp=1&_token=${token}`}>
          <CTA src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6cc4bf98-0995-449b-8583-5af493827900/public" />
        </a>
        <div>
          <ImageStyled
            src="https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/98eb6628-8aa4-459c-841a-6ababfc73e00/public"
            alt="nois-bet"
          />
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
